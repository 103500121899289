import { Component, OnInit} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  animations:[
    trigger(
      'isOpenNav', 
      [
        transition(
          ':enter', 
          [
            style({ 
              transform:'scaleX(0)',
              opacity: 0 }),
            animate('.5s ease-in', 
              style({ 
                transform:'scaleX(1)',
                opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({
              transform:'scaleX(1)',
               opacity: 1 }),
            animate('.5s .55s ease-out', 
              style({ 
                transform:'scaleX(0)',
                opacity: 0 }))
          ]
        )
      ]
    ),
  ]
})
export class MainNavigationComponent implements OnInit {
  isOpen: boolean = false;
  isShow: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  isOpenSubMenu(e:any){
    e.preventDefault();
    this.isShow = !this.isShow;
  }

  isOpenMenu(){
    this.isOpen = !this.isOpen;
  }
}
