import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-title-off',
  templateUrl: './main-title-off.component.html',
  styleUrls: ['./main-title-off.component.scss']
})
export class MainTitleOffComponent implements OnInit {
  @Input() title: string = 'FiouxLabs: Cutting-edge technology solutions and UX / UI Management. IT and UX services.';
  constructor() { }

  ngOnInit() {
  }

}
