import { Component, OnInit, Input } from '@angular/core';
import { CurrentPageService } from '../../../services/current-page/current-page.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() set: string = null;
  isHome: boolean;
  constructor(protected croute: CurrentPageService) { }

  ngOnInit() {
    this.gRoute();
  }

  gRoute(){
    this.isHome = this.croute.getCurrentRoute();
  }

}
