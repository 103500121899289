import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { SecondaryNavigationComponent } from './secondary-navigation/secondary-navigation.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button'; 
import { StarsFieldComponent } from './stars-field/stars-field.component';
import { LogoComponent } from './logo/logo.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { AnimTxtComponent } from './anim-txt/anim-txt.component';
import { SpinnerComponent } from './spinner/spinner.component'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SateliteComponent } from './satelite/satelite.component';
import { MainTitleOffComponent } from './main-title-off/main-title-off.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { PageBoxComponent } from './page-box/page-box.component';

@NgModule({
  declarations: [MainWrapperComponent, SecondaryNavigationComponent, StarsFieldComponent, LogoComponent, MainNavigationComponent, AnimTxtComponent, SpinnerComponent, SateliteComponent, MainTitleOffComponent, SocialShareComponent, PageBoxComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterModule
  ],
  exports:[
    MainWrapperComponent,
    SecondaryNavigationComponent,
    StarsFieldComponent,
    LogoComponent,
    MainNavigationComponent,
    AnimTxtComponent,
    SpinnerComponent,
    MainTitleOffComponent,
    SateliteComponent,
    SocialShareComponent,
    PageBoxComponent
  ]
})
export class CommonToolsModule { }
