import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets/assets.service'

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  logo: string = null;
  isPreload: boolean = true;
  constructor(protected glogo: AssetsService) { }

  ngOnInit() {
    this.getLogo(); 
  }

  getLogo(){
    this.glogo.getAssets()
    .subscribe(
      (data) => { // Success
        this.logo = data[0].logo;
        this.isPreload = false;
      },
      (error) => {
        console.error(error);
      }, 
 
    );
  }

}
