import { Component, OnInit, Input } from '@angular/core';
import { AssetsService } from '../../services/assets/assets.service'

@Component({
  selector: 'app-page-box',
  templateUrl: './page-box.component.html',
  styleUrls: ['./page-box.component.scss']
})
export class PageBoxComponent implements OnInit {
  @Input() pbTitle: string = 'ux';
  pbg: string = null;
  pClass: string = null;
  isPreload: boolean = true;

  constructor(protected bg: AssetsService) { }

  ngOnInit() {
    this.getBg();
  }

  getBg(){
    this.bg.getAssets()
    .subscribe(
      (data) => {
        this.pbg = data[0].pagebg;
        this.isPreload = false;
      },
      (error) => {
        console.error(error);
      },

    )
  }

  get pageClass(){
    return this.pbTitle;
  }

}
