import { Component, OnInit,  Renderer2, ElementRef, ViewChild } from '@angular/core';
import { CurrentPageService } from '../../services/current-page/current-page.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-secondary-navigation',
  templateUrl: './secondary-navigation.component.html',
  styleUrls: ['./secondary-navigation.component.scss'],
  animations: [
    trigger(
      'inOutSubMenu', 
      [
        transition(
          ':enter', 
          [
            style({ 
              opacity: 0 }),
            animate('.5s ease-out', 
              style({ 
                opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({
               opacity: 1 }),
            animate('.5s .55s ease-in', 
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inOutSubNavigation', 
      [
        transition(
          ':enter', 
          [
            style({ 
              transform:'scaleY(0)',
              opacity: 0 }),
            animate('.5s ease-in', 
              style({ 
                transform:'scaleY(1)',
                opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({
              transform:'scaleY(1)',
               opacity: 1 }),
            animate('.5s .45s ease-out', 
              style({ 
                transform:'scaleY(0)',
                opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SecondaryNavigationComponent implements OnInit {
  isHome: boolean;
  isShow: boolean = false;
  constructor(private router : CurrentPageService, private renderer: Renderer2) { }

  ngOnInit() {
    this.gCurrentRoute();
  }

  gCurrentRoute(){
   this.isHome = this.router.getCurrentRoute();
  }

  isOpenSubNav(){
   // e.preventDefault();
    this.isShow = !this.isShow;
  }

  closeOthers(){
    console.log('close');
  }
}
