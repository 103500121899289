import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Space } from '../../interfaces/space';

@Injectable({
  providedIn: 'root'
})

export class AssetsService {
  constructor(private db: AngularFirestore) { }
  getAssets(): Observable<any> {
    return this.db.collection('assets').valueChanges();
  }
 
}
