// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB-Pb5q0MwZriksHBjjmwFUNzQ5pHQPS7Q",
    authDomain: "fiouxlabs.firebaseapp.com",
    databaseURL: "https://fiouxlabs.firebaseio.com",
    projectId: "fiouxlabs",
    storageBucket: "fiouxlabs.appspot.com",
    messagingSenderId: "416077822512",
    appId: "1:416077822512:web:21d07cce88d03bbe"  
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
