import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CurrentPageService {

  constructor(public location: Location, public router: Router) {}

  getCurrentRoute(){
    let cpage: string = this.location.path(); 
    if(cpage === '/home' || cpage === ''){
      return true;
    }else {
      return false;
    }
  };

  getRoute(){
    return location;
  }
}
