import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-stars-field',
  templateUrl: './stars-field.component.html',
  styleUrls: ['./stars-field.component.scss']
})
export class StarsFieldComponent implements OnInit {
  stars: any[] = Array(5).fill(0).map((x,i)=>i);
  constructor() { }

  ngOnInit() {
    
  }

 
}
