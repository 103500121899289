import { NgModule } from '@angular/core';
import { Routes, RouterModule,  PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './core/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren : () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home',
    loadChildren : () => import('./home/home.module').then(m => m.HomeModule), 
  },
  {
    path: 'development',
    loadChildren : () => import('./development/development.module').then(m => m.DevelopmentModule), 
  },
  {
    path: 'ux',
    loadChildren : () => import('./ux/ux.module').then(m => m.UxModule), 
  },
  {
    path: 'tech',
    loadChildren : () => import('./tech/tech.module').then(m => m.TechModule), 
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
  { path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule) },
  { path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { preloadingStrategy: PreloadAllModules }  
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
