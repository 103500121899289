import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets/assets.service';
@Component({
  selector: 'app-satelite',
  templateUrl: './satelite.component.html',
  styleUrls: ['./satelite.component.scss']
})
export class SateliteComponent implements OnInit {
  satelite: string = null;
  constructor(protected assets: AssetsService) { }

  ngOnInit() {
    this.gAssets(); 
  }

  gAssets(){
    this.assets.getAssets()
    .subscribe(
      (data) => { // Success
        console.log(data[0].satelite);
        this.satelite = data[0].satelite;
      },
      (error) => {
        console.error(error);
      }, 
    );
  }

}
