import { Component, OnInit, Renderer2,ElementRef,ViewChild} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { CurrentPageService } from '../../services/current-page/current-page.service';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  animations:[
    trigger(
      'isShareNav', 
      [
        transition(
          ':enter', 
          [
            style({ 
              transform:'scaleY(0) translate3d(-50%,0,0) rotateY(0)',
              opacity: 0 }),
            animate('.5s ease-in', 
              style({ 
                transform:'scaleY(1) translate3d(-50%,0,0) rotateY(360deg)',
                opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({
              transform:'scaleY(1) translate3d(-50%,0,0) rotateY(360deg)',
               opacity: 1 }),
            animate('.5s .15s ease-out', 
              style({ 
                transform:'scaleY(0) translate3d(-50%,0,0) rotateY(0)',
                opacity: 0 }))
          ]
        )
      ]
    ),
  ]
})

export class SocialShareComponent implements OnInit {
 @ViewChild('toggleButton', {static: false}) toggleButton: ElementRef;
  @ViewChild('sharenav', {static: false}) sharenav: ElementRef;
  isShare: boolean = false;
  shareTxt: string = 'Hola%20te%20comparto%20Fiouxlabs';
  croute: any;
  shareArr: any [] = [];
  isHome: boolean;
 
  constructor(protected currentRoute: CurrentPageService, private renderer: Renderer2) { }

  ngOnInit() {
    this.gRoute(); 
    //this.clickOutSide();
  }

  gRoute(){
   let croute = this.currentRoute.getRoute(),
   shareRoute: any[] = [
    {
      shareby: 'fab fa-whatsapp',
      url: `https://api.whatsapp.com/send?text=${this.shareTxt}%20-%20${croute}`
    },
    {
      shareby: 'fab fa-twitter',
      url: `https://twitter.com/intent/tweet?url=${croute}`
    },
    {
      shareby: 'fab fa-facebook-f',
      url: `https://www.facebook.com/sharer.php?u=${croute}`
    },
    {
      shareby: 'fab fa-linkedin-in',
      url: `https://www.linkedin.com/shareArticle?url=${croute}`
    },
    {
      shareby: 'far fa-envelope',
      url: `mailto:?Subject=Hola%20te%20comparto%20Fiouxlabs.web.app&body=Mira%20este%20sitio%20${croute}`
    },
  ];
  return this.shareArr = shareRoute;  
    
  }

 /* clickOutSide(){
    this.renderer.listen('window', 'click',(e:Event)=>{
      
       

     if(e.target !== this.toggleButton.nativeElement ||  e.target !== this.sharenav.nativeElement){
      this.isShare = !this.isShare;
     }
    
 });
  }*/

  shareMenu($event:any){
    $event.preventDefault();
    this.isShare = !this.isShare;
  }


}
