import { Directive, Renderer2, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appTimerOn]'
})
export class TimerOnDirective implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }
  
  ngOnInit() {
    this.timerOn();
  };

  timerOn(){
    setTimeout(()=>{ this.renderer.addClass(this.el.nativeElement, 'onElem'); }, 5000);
  }

}
